import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"


// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBXx4a0qXtMEOhHsqDjEOgvu_tyCtDcA9c",
  authDomain: "lacetalk-web-iv.firebaseapp.com",
  projectId: "lacetalk-web-iv",
  storageBucket: "lacetalk-web-iv.appspot.com",
  messagingSenderId: "16978065296",
  appId: "1:16978065296:web:35f905c632c7d903dda515"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
